import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import Valentine from "./pages/Valentine";
import "./App.css";
import { analyticsPromise } from "./config/Firebase/firebase";

function App() {
  const [analytics, setAnalytics] = useState<any>(null);

  useEffect(() => {
    // Initialize Firebase Analytics
    analyticsPromise.then((analyticsInstance) => {
      if (analyticsInstance) {
        setAnalytics(analyticsInstance);
      }
    });
  }, []);

  return (
    <React.StrictMode>
      <BrowserRouter>
        <Routes>
          {/* <Route path="/" element={<Layout />}> */}
          <Route path="/" element={<Navigate to="/valentine/2025" />}>
            {/* <Route index element={<Home />} /> */}
            {/* <Route path="valentine/2025" element={<Valentine />} /> */}
          </Route>
          <Route path="/valentine/2025" element={<Valentine />} />
        </Routes>
      </BrowserRouter>
    </React.StrictMode>
  );
}

export default App;
