import React from "react";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid2";
import Tooltip from "@mui/material/Tooltip";
import Chip from "@mui/material/Chip";
import Link from "@mui/material/Link";
import { red, green } from "@mui/material/colors";
import { Item } from "../types/common";
import "./ItemCard.css";

function isTodayOrFuture(date: Date): boolean {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const givenDate = new Date(date);
  givenDate.setHours(0, 0, 0, 0);

  return givenDate >= today;
}

function truncateString(str: string, maxLength: number): string {
  return str.length > maxLength ? str.substring(0, maxLength) + "..." : str;
}

function extractFacebookIDs(url: string): {
  fbid: string | null;
  id: string | null;
  pageName: string | null;
} {
  const pageRegex = /facebook\.com\/([^\/]+)\/posts/;
  const fbidRegex = /fbid=([\w]+).*id=(\w+)|\/posts\/([\w]+)/;

  const pageMatch = url.match(pageRegex);
  const fbidMatch = url.match(fbidRegex);

  return {
    pageName: pageMatch ? pageMatch[1] : null,
    fbid: fbidMatch ? fbidMatch[1] || fbidMatch[3] : null,
    id: fbidMatch?.[2] ? fbidMatch[2] : null,
  };
}

const ItemCard: React.FC<Item> = ({
  fbPostLink,
  isFullPost,
  isHalfPost,
  company,
  endDate,
  postTypes,
  categories,
  locationTypes,
  locations,
  districts,
  status,
  createdDateTime,
}) => {
  const fbIds = extractFacebookIDs(fbPostLink);
  const avatarLetter = company.charAt(0).toUpperCase();
  const isExpired = !isTodayOrFuture(new Date(endDate));
  return (
    <>
      {fbIds?.fbid && (
        <Link
          href={fbPostLink}
          underline="none"
          target="_blank"
          rel="noreferrer"
        >
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Card sx={{ maxWidth: 350, height: 420, paddingBottom: "10px" }}>
              {/* <CardHeader
              avatar={
                <Avatar sx={{ bgcolor: red[500] }} aria-label="logo">
                  {avatarLetter}
                </Avatar>
              }
              title={company}
              subheader={`Valid Until: ${endDate}`}
            /> */}
              <CardMedia component="div">
                {!isFullPost && !isHalfPost ? (
                  <iframe
                    src={
                      fbIds.pageName
                        ? `https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fweb.facebook.com%2F${fbIds.pageName}%2Fposts%2F${fbIds.fbid}&show_text=true&appId`
                        : `https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fweb.facebook.com%2Fpermalink.php%3Fstory_fbid%3D${fbIds.fbid}%26id%3D${fbIds.id}&show_text=true`
                    }
                    width="276"
                    height="273"
                    style={{
                      border: "none",
                      overflow: "hidden",
                      borderRadius: "0 10px",
                    }}
                    scrolling="no"
                    frameBorder="0"
                    allowFullScreen={true}
                    // allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                  ></iframe>
                ) : isFullPost ? (
                  <div className="iframe-container-full">
                    <iframe
                      src={
                        fbIds.pageName
                          ? `https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fweb.facebook.com%2F${fbIds.pageName}%2Fposts%2F${fbIds.fbid}&show_text=false&appId`
                          : `https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fweb.facebook.com%2Fpermalink.php%3Fstory_fbid%3D${fbIds.fbid}%26id%3D${fbIds.id}&show_text=false`
                      }
                      width="600"
                      height="730"
                      scrolling="no"
                      frameBorder="0"
                      allowFullScreen={true}
                      // allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                    ></iframe>
                  </div>
                ) : (
                  <div className="iframe-container-half">
                    <iframe
                      src={
                        fbIds.pageName
                          ? `https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fweb.facebook.com%2F${fbIds.pageName}%2Fposts%2F${fbIds.fbid}&show_text=false&appId`
                          : `https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fweb.facebook.com%2Fpermalink.php%3Fstory_fbid%3D${fbIds.fbid}%26id%3D${fbIds.id}&show_text=false`
                      }
                      width="600"
                      height="630"
                      scrolling="no"
                      frameBorder="0"
                      allowFullScreen={true}
                      // allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                    ></iframe>
                  </div>
                )}
              </CardMedia>
              <CardContent>
                {/* Time Status */}
                {isExpired ? (
                  <Typography
                    variant="body2"
                    sx={{ color: red[500], fontWeight: "bold" }}
                  >
                    {`Ended Recently`}
                  </Typography>
                ) : (
                  <Grid
                    container
                    spacing={2}
                    direction="row"
                    sx={{
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{ color: green[500], fontWeight: "bold" }}
                    >
                      {`Ongoing Deal`}
                    </Typography>
                    <Tooltip title="Deal ends on:">
                      <Typography variant="body2" sx={{ color: green[500] }}>
                        {`${endDate}`}
                      </Typography>
                    </Tooltip>
                  </Grid>
                )}
                {/* Categories */}
                <Chip
                  label={`${truncateString(categories.join(", "), 30)}`}
                  color="primary"
                  variant="outlined"
                  sx={{
                    marginY: 1,
                    marginX: 0,
                    borderRadius: 1,
                  }}
                />
                <Typography
                  variant="subtitle2"
                  sx={{ color: "text.secondary", fontWeight: "bold" }}
                >
                  {`${locationTypes.join(" | ")}`}
                </Typography>
                {locations?.length > 0 && (
                  <Typography
                    variant="subtitle2"
                    sx={{ color: "text.secondary" }}
                  >
                    {`${truncateString(locations.join(" | "), 35)}`}
                  </Typography>
                )}
                {/* {districts?.length > 0 && !districts.includes("All Island") && (
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  {`City: ${districts.join(", ")}`}
                </Typography>
              )} */}
              </CardContent>
              {/* <CardActions disableSpacing>
              <IconButton aria-label="add to favorites">
                <FavoriteIcon />
              </IconButton>
              <IconButton aria-label="share">
                <ShareIcon />
              </IconButton>
            </CardActions> */}
            </Card>
          </div>
        </Link>
      )}
    </>
  );
};

export default ItemCard;
