import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Switch from "@mui/material/Switch";
import ItemCard from "../components/ItemCard";
import data from "./data.json";
import { Item } from "../types/common";
import { AppBar, Pagination, Stack, useMediaQuery } from "@mui/material";
import "./Valentine.css";
import { onAnalytics } from "../utils/analytics";

function roundDownToNearestFive(num: number): number {
  return Math.floor(num / 5) * 5;
}

function isTodayOrFuture(date: Date): boolean {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const givenDate = new Date(date);
  givenDate.setHours(0, 0, 0, 0);

  return givenDate >= today;
}

function Valentine() {
  const [selectedCompany, setSelectedCompany] = useState<string | null>(null);
  const [selectedPostType, setSelectedPostType] = useState<
    string | null | undefined
  >(null);
  const [selectedCategory, setSelectedCategory] = useState<
    string | null | undefined
  >(null);
  const [selectedCity, setSelectedCity] = useState<string | null | undefined>(
    null
  );
  const [selectedSort, setSelectedSort] = useState<string>("End Date (Asc)");
  const [isOngoingChecked, setIsOngoingChecked] = useState(true);

  let initialFilteredData = data.data.filter((item: Item) => {
    return isTodayOrFuture(new Date(item.endDate));
  });
  initialFilteredData = initialFilteredData.sort((a: Item, b: Item) => {
    if (selectedSort === "End Date (Asc)") {
      return new Date(a.endDate).getTime() - new Date(b.endDate).getTime();
    } else {
      return new Date(b.endDate).getTime() - new Date(a.endDate).getTime();
    }
  });
  const expiredPostCount = data.data.length - initialFilteredData.length;

  const [page, setPage] = useState(1);
  const itemsPerPage = 12;

  const [filteredData, setFilteredData] = useState<Item[]>(initialFilteredData);

  const displayItems = filteredData.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const bannerHeight =
        document.getElementById("top-banner")?.offsetHeight || 0;
      setIsFixed(window.scrollY > bannerHeight);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const isSmallScreen = useMediaQuery("(max-width:700px)");

  const companyList = [
    ...new Set(data.data.map((item: Item) => item.company).sort()),
  ];
  const postTypesList = [
    ...new Set(
      data.data
        .map((item: Item) => item.postTypes)
        .flat()
        .sort()
    ),
  ];
  const categoriesList = [
    ...new Set(
      data.data
        .map((item: Item) => item.categories)
        .flat()
        .sort()
    ),
  ];
  const cityList = [
    ...new Set(
      data.data
        .map((item: Item) => item.districts)
        .flat()
        .sort()
    ),
  ];
  const sortList = ["End Date (Asc)", "End Date (Desc)"];

  const handleCompanyChange = (event: any, value: string | null) => {
    onAnalytics("select_content", {
      content_type: "company",
      content_id: value,
    });
    setSelectedCompany(value);
    const isCompany = true;
    filterData(
      value,
      isCompany,
      false,
      false,
      false,
      isOngoingChecked,
      selectedSort
    );
  };

  const handlePostTypeChange = (
    event: any,
    value: string | null | undefined
  ) => {
    onAnalytics("select_content", {
      content_type: "deal_type",
      content_id: value,
    });
    setSelectedPostType(value);
    const isPostType = true;
    filterData(
      value,
      false,
      isPostType,
      false,
      false,
      isOngoingChecked,
      selectedSort
    );
  };

  const handleCategoryChange = (
    event: any,
    value: string | null | undefined
  ) => {
    onAnalytics("select_content", {
      content_type: "category",
      content_id: value,
    });
    setSelectedCategory(value);
    const isCategory = true;
    filterData(value, false, false, isCategory, false, isOngoingChecked);
  };

  const handleCityChange = (event: any, value: string | null | undefined) => {
    onAnalytics("select_content", {
      content_type: "district",
      content_id: value,
    });
    setSelectedCity(value);
    const isCity = true;
    filterData(
      value,
      false,
      false,
      false,
      isCity,
      isOngoingChecked,
      selectedSort
    );
  };

  const handleIsOngoingChange = (event: any) => {
    onAnalytics("select_content", {
      content_type: "is_ongoing_clicked",
      content_id: event.target.checked,
    });
    setIsOngoingChecked(event.target.checked);
    const isChecked = event.target.checked;
    filterData(null, false, false, false, false, isChecked, selectedSort);
  };

  const handleSortChange = (event: any, value: string | null | undefined) => {
    onAnalytics("select_content", {
      content_type: "sort_by",
      content_id: value,
    });
    const sortBy = value || "End Date (Asc)";
    setSelectedSort(sortBy);
    filterData(null, false, false, false, false, isOngoingChecked, sortBy);
  };

  const handlePageChange = (event: any, value: number) => {
    onAnalytics("page_view", {
      page_number: value,
    });
    setPage(value);
  };

  const filterData = (
    value: string | null | undefined,
    isCompany?: Boolean,
    isPostType?: Boolean,
    isCategory?: Boolean,
    isCity?: Boolean,
    isOngoing?: Boolean,
    sortBy?: string
  ) => {
    let filteredData = [...data.data];

    if (isCompany && value) {
      filteredData = filteredData.filter((item) => item.company === value);
    } else if (!isCompany && selectedCompany) {
      filteredData = filteredData.filter(
        (item) => item.company === selectedCompany
      );
    }

    if (isPostType && value) {
      filteredData = filteredData.filter((item) =>
        item.postTypes?.includes(value)
      );
    } else if (!isPostType && selectedPostType) {
      filteredData = filteredData.filter((item) =>
        item.postTypes?.includes(selectedPostType)
      );
    }

    if (isCategory && value) {
      filteredData = filteredData.filter((item) =>
        item.categories?.includes(value)
      );
    } else if (!isCategory && selectedCategory) {
      filteredData = filteredData.filter((item) =>
        item.categories?.includes(selectedCategory)
      );
    }

    if (isCity && value) {
      filteredData = filteredData.filter((item) =>
        item.districts?.includes(value)
      );
    } else if (!isCity && selectedCity) {
      filteredData = filteredData.filter((item) =>
        item.districts?.includes(selectedCity)
      );
    }

    if (isOngoing) {
      filteredData = filteredData.filter((item) =>
        isTodayOrFuture(new Date(item.endDate))
      );
    }

    filteredData = filteredData.sort((a: Item, b: Item) => {
      if (sortBy === "End Date (Asc)") {
        return new Date(a.endDate).getTime() - new Date(b.endDate).getTime();
      } else {
        return new Date(b.endDate).getTime() - new Date(a.endDate).getTime();
      }
    });

    setFilteredData(filteredData);
    setPage(1);
  };

  return (
    <div>
      <div id="top-banner" className="banner-container">
        {/* Hero Section */}
        <div className="bg-image"></div>
        <div>
          <Container maxWidth="lg" sx={{ marginTop: 10 }}>
            <Typography variant="h4" gutterBottom>
              Valentine with OffersLK
            </Typography>
            <Typography variant="h2" gutterBottom sx={{ fontWeight: "bold" }}>
              Exclusive 'Valentine Deals' for You!
            </Typography>
            <Typography variant="h5" gutterBottom>
              Find the best Valentine Discounts, Offers & Giveaways from top
              stores in Sri Lanka.
            </Typography>
            <Typography variant="body2" gutterBottom>
              Make this February extra special with exclusive deals from your
              favorite stores! Whether you're searching for the perfect gift, a
              romantic dinner, or a special getaway, we've got you covered.
              Browse through these deals and surprise your loved one with
              something truly memorable. Hurry! Limited-time deals await you.
            </Typography>
            {/* <Button variant="contained">
              💖 Explore Now & Spread the Love! 💖
            </Button> */}
          </Container>
        </div>
        {/* Counter Section */}
        <div>
          <Container maxWidth="lg" sx={{ marginTop: 5, marginBottom: 10 }}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid
                container
                spacing={2}
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Grid size={{ xs: 12, sm: 6, md: 4 }}>
                  <Paper
                    elevation={3}
                    sx={{
                      height: 150,
                      textAlign: "center",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: 4,
                    }}
                  >
                    <Typography variant="h4" gutterBottom>
                      {`${roundDownToNearestFive(companyList.length)}+`}
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                      brands & stores to choose from 💪
                    </Typography>
                  </Paper>
                </Grid>
                <Grid size={{ xs: 12, sm: 6, md: 4 }}>
                  <Paper
                    elevation={3}
                    sx={{
                      height: 150,
                      textAlign: "center",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: 4,
                    }}
                  >
                    <Typography variant="h4" gutterBottom>
                      {`${roundDownToNearestFive(data.data.length)}+`}
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                      deals so far 😍
                    </Typography>
                  </Paper>
                </Grid>
                <Grid size={{ xs: 12, sm: 6, md: 4 }}>
                  <Paper
                    elevation={3}
                    sx={{
                      height: 150,
                      textAlign: "center",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: 4,
                    }}
                  >
                    {/* <Typography variant="h6" gutterBottom>
                      From
                    </Typography>
                    <Typography variant="h5" gutterBottom>
                      All arround the Country
                    </Typography> */}
                    <Typography variant="h4" gutterBottom>
                      {expiredPostCount <= 5
                        ? expiredPostCount
                        : `${expiredPostCount}`}
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                      chances you've already missed 😢
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </div>
      </div>
      {/* Page Content */}
      <div style={{ backgroundColor: "white" }}>
        {" "}
        <Divider />
        <Container maxWidth="lg" sx={{ marginTop: 2, marginBottom: 10 }}>
          {/* Filters */}
          <AppBar
            position={isFixed ? "fixed" : "static"}
            sx={{
              top: isFixed ? 0 : "auto",
              transition: "top 0.3s ease-in-out",
              zIndex: 1100,
              backgroundColor: "white",
              boxShadow: isFixed ? "0px 2px 4px rgba(0, 0, 0, 0.1)" : "none",
              padding: "20px 0",
              paddingX: isFixed ? "5%" : 0,
              marginBottom: 2,
              maxHeight: isFixed && isSmallScreen ? 160 : "auto",
              overflow: isFixed && isSmallScreen ? "scroll" : "hidden",
            }}
          >
            <Grid
              container
              spacing={2}
              direction="row"
              sx={{
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Autocomplete
                options={companyList}
                getOptionLabel={(option) => option}
                value={selectedCompany}
                onChange={handleCompanyChange}
                sx={{ width: "20%", minWidth: 250 }}
                renderInput={(params) => (
                  <TextField {...params} label="Brand" />
                )}
              />
              <Autocomplete
                options={postTypesList}
                getOptionLabel={(option) => option || ""}
                value={selectedPostType}
                onChange={handlePostTypeChange}
                sx={{ width: "15%", minWidth: 180 }}
                renderInput={(params) => (
                  <TextField {...params} label="Deal Type" />
                )}
              />
              <Autocomplete
                options={categoriesList}
                getOptionLabel={(option) => option || ""}
                value={selectedCategory}
                onChange={handleCategoryChange}
                sx={{ width: "15%", minWidth: 180 }}
                renderInput={(params) => (
                  <TextField {...params} label="Deal Category" />
                )}
              />
              <Autocomplete
                options={cityList}
                getOptionLabel={(option) => option || ""}
                value={selectedCity}
                onChange={handleCityChange}
                sx={{ width: "12%", minWidth: 150 }}
                renderInput={(params) => (
                  <TextField {...params} label="District" />
                )}
              />
              <Grid
                container
                spacing={0}
                direction="column"
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                  width: "10%",
                  minWidth: 100,
                }}
              >
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  Ongoing Deals
                </Typography>
                <Switch
                  size="small"
                  checked={isOngoingChecked}
                  onChange={handleIsOngoingChange}
                />
              </Grid>
              <Autocomplete
                disableClearable
                options={sortList}
                getOptionLabel={(option) => option || ""}
                value={selectedSort}
                onChange={handleSortChange}
                sx={{ width: "15%", minWidth: 180 }}
                renderInput={(params) => (
                  <TextField {...params} label="Sort by" />
                )}
              />
            </Grid>
            <Typography
              variant="subtitle1"
              sx={{
                color: "text.secondary",
                padding: 0,
                margin: 0,
                marginTop: 1,
                textAlign: "center",
              }}
            >
              Showing {displayItems.length} of {filteredData.length} results
            </Typography>
          </AppBar>

          {/* Items */}
          <Grid
            container
            spacing={2}
            direction="row"
            sx={{
              alignItems: "center",
              justifyContent: filteredData.length > 0 ? "normal" : "center",
              marginTop: isFixed ? "250px" : 0,
            }}
          >
            {Array.isArray(filteredData) && filteredData.length > 0 ? (
              displayItems.map((item: Item) => (
                <Grid
                  key={item.id}
                  size={{ xs: 12, sm: 6, md: 4, lg: 3 }}
                  onClick={() =>
                    onAnalytics("view_item", {
                      affiliation: `${item.company}`,
                      item_category: `${
                        item.categories?.[0] ? item.categories?.[0] : ""
                      }`,
                      item_category2: `${
                        item.categories?.[1] ? item.categories?.[1] : ""
                      }`,
                      item_category3: `${
                        item.categories?.[2] ? item.categories?.[2] : ""
                      }`,
                      item_category4: `${
                        item.categories?.[3] ? item.categories?.[3] : ""
                      }`,
                      item_category5: `${
                        item.categories?.[4] ? item.categories?.[4] : ""
                      }`,
                      // custom fields
                      post_type: `${
                        item.postTypes?.[0] ? item.postTypes?.[0] : ""
                      }`,
                      district: `${
                        item.districts?.[0] ? item.districts?.[0] : ""
                      }`,
                      // TODO:check whether which data is used for analytics
                      items: [
                        {
                          affiliation: `${item.company}`,
                          item_category: `${
                            item.categories?.[0] ? item.categories?.[0] : ""
                          }`,
                          item_category2: `${
                            item.categories?.[1] ? item.categories?.[1] : ""
                          }`,
                          item_category3: `${
                            item.categories?.[2] ? item.categories?.[2] : ""
                          }`,
                          item_category4: `${
                            item.categories?.[3] ? item.categories?.[3] : ""
                          }`,
                          item_category5: `${
                            item.categories?.[4] ? item.categories?.[4] : ""
                          }`,
                          // custom fields
                          post_type: `${
                            item.postTypes?.[0] ? item.postTypes?.[0] : ""
                          }`,
                          post_type1: `${
                            item.postTypes?.[1] ? item.postTypes?.[1] : ""
                          }`,
                          post_type2: `${
                            item.postTypes?.[2] ? item.postTypes?.[2] : ""
                          }`,
                          district: `${
                            item.districts?.[0] ? item.districts?.[0] : ""
                          }`,
                          district1: `${
                            item.districts?.[1] ? item.districts?.[1] : ""
                          }`,
                          district2: `${
                            item.districts?.[2] ? item.districts?.[2] : ""
                          }`,
                        },
                      ],
                    })
                  }
                >
                  <ItemCard
                    id={item.id}
                    fbPostLink={item.fbPostLink}
                    isFullPost={item.isFullPost}
                    isHalfPost={item.isHalfPost}
                    company={item.company}
                    endDate={item.endDate}
                    postTypes={item.postTypes}
                    categories={item.categories}
                    locationTypes={item.locationTypes}
                    locations={item.locations}
                    districts={item.districts}
                    status={item.status}
                    createdDateTime={item.createdDateTime}
                  />
                </Grid>
              ))
            ) : (
              <Typography
                variant="subtitle1"
                sx={{
                  color: "text.secondary",
                  fontWeight: "bold",
                  height: 300,
                }}
              >
                No results found!
              </Typography>
            )}
          </Grid>
          <Grid
            container
            spacing={2}
            direction="row"
            sx={{
              alignItems: "center",
              justifyContent: "center",
              marginTop: 5,
            }}
          >
            <Stack spacing={2}>
              <Pagination
                count={Math.ceil(filteredData.length / itemsPerPage)}
                defaultPage={6}
                siblingCount={1}
                boundaryCount={1}
                page={page}
                onChange={handlePageChange}
                variant="outlined"
                shape="rounded"
                color="primary"
                showFirstButton
                showLastButton
              />
            </Stack>
          </Grid>
        </Container>
      </div>
      {/* Footer */}
      <Divider sx={{ marginBottom: 2 }} />
      <Grid
        container
        spacing={{ xs: 0, sm: 3 }}
        direction={{ xs: "column", sm: "row" }}
        sx={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          variant="subtitle2"
          sx={{
            color: "text.secondary",
            fontWeight: "bold",
            marginBottom: 2,
          }}
        >
          Made with ❤️ by OffersLK
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{
            color: "text.secondary",
            fontWeight: "bold",
            marginBottom: 2,
          }}
        >
          {" "}
          All rights reserved © 2025{" "}
        </Typography>
      </Grid>
    </div>
  );
}

export default Valentine;
