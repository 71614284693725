import { logEvent } from "firebase/analytics";
import { analyticsPromise } from "../config/Firebase/firebase";

export const onAnalytics = (eventName: string, eventParams: any) => {
  eventParams.environment = process.env.REACT_APP_CUSTOM_NODE_ENV
    ? process.env.REACT_APP_CUSTOM_NODE_ENV
    : process.env.NODE_ENV;

  //   console.log("eventName", eventName);
  //   console.log("eventParams", eventParams);

  analyticsPromise.then((analyticsInstance) => {
    if (analyticsInstance) {
      logEvent(analyticsInstance, eventName, eventParams);
    }
  });
};
